@import "../variables";

.dc-search-sidebar-options {
  background-color: #ffffff;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
  padding: 30px;
  margin-bottom: 25px;
  select {
    border-color: $borderColor;
    border-radius: $borderRadius;
  }
  .toggle-block {
    margin-top: 0;
    margin-bottom: 20px;
    border-top: 1px solid $borderColor;
    padding-top: 3rem;
    &:first-of-type {
      border: none;
      padding-top: 0;
    }
    button {
      background: transparent;
      border: none;
      width: 100%;
      text-align: left;
      padding: 0;
    }
    h2 {
      position: relative;
    }
    svg {
      margin-right: 15px;
    }
  }
  // Facets.
  .show-more-container .dc-facet-option {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    font-weight: 400;
    padding: 8px 0;
    max-width: 100%;
    min-height: 32px;
    position: relative;
    label {
      display: block;
      cursor: pointer;
      width: 100%;
    }
    input {
      left: -999em;
      opacity: 0;
      position: absolute;
    }
    svg {
      vertical-align: middle;
      margin-right: 15px;
      height: 24px;
      width: 24px;
      &.fa-square {
        color: $grayLight;
      }
      &.fa-check-square {
        color: $primary;
      }
    }
  }
  button.showmore-button {
    list-style-type: none;
    line-height: 22px;
    width: 100%;
    position: relative;
    display: block;
    color: $linkColor;
    margin: 2rem 0px;
    padding: 5px;
    background: transparent;
    text-align: center;
  }
}
