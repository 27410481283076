@import "../variables";

.dc-block-wrapper {
  background: white;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
  padding: 1em;
  margin-bottom: 32px;
  svg.fa-arrow-left {
    margin-right: 1rem;
  }
}
.dc-block-content {
    display: flex;
    align-items: flex-start;
    align-content: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 0;
    h2,
    h3 {
        margin-top: 0;
        padding-top: 50px;
    }
    h2 {
        text-align: center;
        padding-top: 50px;
        margin: 0;
    }
}

.BasicBlock {
    h2 {
        padding-bottom: 1.6rem;
    }
    .basic-block {
        padding: 0 20px;
        min-width: 33%;
    }
}
.StatBlock {
    .dc-block-content {
        max-width: 1100px;
        margin: 0 auto;
    }
    background-color: $primary;
    color: #ffffff;
    position: relative;
    .stat-block {
        margin-top: 50px;
        margin-bottom: 50px;
        h2 {
            font-size: 64px;
            display: inline-block;
            color: white;
            font-weight: 300;
            margin: 0 0 0 15px;
            padding: 0;
        }
        i {
            color: $primary;
            font-size: 48px;
            display: inline-block;
        }
        p {
            text-align: center;
            margin: 0;
        }
        a {
            text-align: left;
            margin: 0;
            color: #ffffff;
        }
    }
}
.StepsBlock {
    background-color: #ffffff;
    h2 {
      padding-bottom: 1.6rem;
    }
    .steps-block {
        position: relative;
        padding: 0 20px;
        margin-bottom: 50px;
        text-align: center;
        max-width: 33%;
        h3 {
            margin-top: 65px;
            padding-top: 0;
            text-align: center;
        }
    }
    .steps-step {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 100%;
        border: 1px solid $primaryDust;
        line-height: 50px;
        font-size: 20px;
        color: $primaryDark;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .dc-block-content {
        flex-wrap: wrap;
        .stat-block,
        .steps-block {
            max-width: 100%;
            width: 100%;
            text-align: center;
        }
    }
}
